.neutral-vacation {
  padding: 60px 20px 10px 20px;
}

.form-actions {
  display: none;
}

.neutral-vacation .neutral-vacation-fieldset {
  padding: 30px !important;
  border: 1px solid #d1d1d1 !important;
  border-radius: 10px;
  opacity: 1;
}

.neutral-vacation .fieldsetHeaderContainer {
  margin: 0px;
  display: flex;
  justify-content: space-between;
}

.neutral-vacation .fieldsetHeaderLeft {
  order: 1;
  margin: 0px;
  font: normal normal bold 22px/27px "Helvetica Neue", sans-serif !important;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.neutral-vacation #neutralVacationFieldset {
  border-top: 1px solid #cccccc;
  padding: 20px 40px !important;
}

.neutral-vacation .row.chilld-fieldset > div {
  padding: 0px !important;
}

.neutral-vacation .chilld-fieldset > div {
  border: 1px solid #cccccc;
  border-radius: 10px;
  opacity: 1;
  margin-bottom: 20px;
}

.neutral-vacation .grey-text {
  text-align: left;
  justify-content: left;
  font: normal normal medium 16px/19px "Helvetica Neue", sans-serif !important;
  letter-spacing: 0px;
  color: #777777 !important;
  opacity: 1;
  padding: 10px 0px !important;
}

.neutral-vacation .form-control {
  font-size: 17px;
  color: #062579;
  font-weight: bold;
  height: 3.5rem;
}

.neutral-vacation .custom-label-sep-line {
  width: 100%;
  font-size: 18px;
  display: block;
  border-top: 1px solid #cccccc;
  width: calc(100% + 84px);
  margin-left: -42px;
}

.neutral-vacation .control-lblSeperator1 {
  min-height: 15px !important;
}

.neutral-vacation .form-control.control-startDate {
  margin-right: 10px !important;
}

.neutral-vacation #startDate,
.neutral-vacation #endDate {
  padding-right: 0.75rem !important;
}

.neutral-vacation #note {
  height: auto !important;
}

.neutral-vacation .neutral-vacation-form {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.neutral-vacation .neutral-vacation-form .left-column {
  width: 15%;
  text-align: left;
  font: normal normal bold 32px/39px "Helvetica Neue", sans-serif !important;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  padding: 25px !important;
}

.neutral-vacation .neutral-vacation-form .right-column {
  width: 75%;
}

.neutral-vacation .neutralVacationButtons {
  margin: 10px !important;
  text-transform: capitalize;
  text-align: center;
  font: normal normal bold 18px/22px "Helvetica Neue", sans-serif !important;
  letter-spacing: 0px;
  color: #fef6f6;
  background-color: #1468b1 !important;
  border: 2px solid #1468b1 !important;
  opacity: 1;
  width: 90% !important;
  height: 45px !important;
}

.neutral-vacation .control-undefined {
  display: none !important;
}

.neutral-vacation .text-danger {
  display: flex;
}

/* neutral-vacation-grid */
.neutral-vacation .neutral-vacation-grid-header-wrapper {
  margin: 10px 5px !important;
  display: flex;
  justify-content: space-between;
}

.neutral-vacation #deleteMultipleVacationButton:disabled {
  opacity: 0.6 !important;
}

.neutral-vacation .delete-multiple-vacation-button {
  margin: 0px !important;
  text-transform: capitalize;
  text-align: center;
  font: normal normal bold 18px/22px "Helvetica Neue", sans-serif !important;
  letter-spacing: 0px;
  color: #fef6f6 !important;
  background-color: #1468b1 !important;
  border: 2px solid #1468b1 !important;
  opacity: 1;
  width: 94px;
  height: 32px;
}

.neutral-vacation .neutral-vacation-grid {
  margin: 30px !important;
}

.neutral-vacation .neutral-vacation-grid .main-div > div > div:nth-of-type(2) {
  height: auto !important;
  max-height: 700px !important;
}

.neutral-vacation
  .neutral-vacation-grid
  .main-div
  > div
  > div:nth-of-type(2)
  > div
  > div {
  overflow-y: unset !important;
}

.neutral-vacation .neutral-vacation-grid thead {
  z-index: 1 !important;
  position: sticky !important;
}

.neutral-vacation .neutral-vacation-grid th {
  font: normal normal 500 16px/19px "Helvetica Neue", sans-serif !important;
  color: #777777 !important;
  letter-spacing: 0px;
  opacity: 1;
}

.neutral-vacation .neutral-vacation-grid td {
  font: normal normal 500 17px/21px "Helvetica Neue", sans-serif !important;
  color: #777777 !important;
  letter-spacing: 0px;
  opacity: 1;
  word-break: break-word;
}

.neutral-vacation .neutral-vacation-delete-button {
  text-align: left;
  text-decoration: underline !important;
  font: normal normal bold 17px/21px "Helvetica Neue", sans-serif !important;
  letter-spacing: 0.34px;
  color: #000000 !important;
  opacity: 1;
}

.neutral-vacation .help-icon-wrapper {
  padding-left: 15px;
}

.neutral-vacation .help-icon {
  width: 20px;
  height: 20px;
}

.neutral-vacation .MuiCheckbox-colorSecondary.Mui-checked {
  color: #062579 !important;
}

.neutral-vacation
  tr
  td
  span.MuiCheckbox-root.Mui-disabled
  span.MuiIconButton-label {
  display: none !important;
}

.neutral-vacation-grid-nodata
  .main-div
  > div
  > div:nth-of-type(2)
  .MuiTable-root
  tr
  td:first-child {
  display: table-cell !important;
}

/* grid filter */
.neutral-vacation .neutral-vacation-grid-filter-wrapper {
  margin: 30px !important;
}

.neutral-vacation .neutral-vacation-grid-filter-inputs-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 25px !important;
  padding-left: 5px !important;
}

.neutral-vacation .neutral-vacation-grid-filter-inputs {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.neutral-vacation .neutral-vacation-grid-filter-inputs label {
  width: 100% !important;
  margin-right: 10px !important;
  font: normal normal 500 16px/19px "Helvetica Neue", sans-serif !important;
  color: #000000 !important;
  letter-spacing: 0px;
  opacity: 1;
}

.neutral-vacation .react-date-picker__wrapper,
.neutral-vacation #neutralVacationGridNotesFilter,
.neutral-vacation #neutralVacationGridStatusFilter {
  border: 1px solid #ced4da;
  border-radius: 5px;
  height: 2.4rem;
  width: 100% !important;
}

.neutral-vacation .react-date-picker__button svg {
  display: inherit;
  width: 1.2vw;
}

.neutral-vacation .neutral-vacation-grid-filter-notes,
.neutral-vacation .neutral-vacation-grid-filter-select {
  width: 20.7%;
}

.neutral-vacation .neutral-vacation-grid-filter-buttons-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 20px !important;
}

.neutral-vacation .neutral-vacation-grid-filter-buttons > button {
  background-color: #337ab7 !important;
  box-shadow: none;
  border-radius: 5px;
  font: normal normal bold 18px/22px "Helvetica Neue", sans-serif !important;
  min-width: 115px;
  text-transform: capitalize;
}

/* grid column width */
.neutral-vacation .MuiTable-root tr th:nth-child(1),
.neutral-vacation .MuiTable-root tr td:nth-child(1) {
  display: none !important;
}

.neutral-vacation .MuiTable-root tr th:nth-child(2),
.neutral-vacation .MuiTable-root tr td:nth-child(2) {
  width: 1% !important;
}

.neutral-vacation .MuiTable-root tr th:nth-child(3),
.neutral-vacation .MuiTable-root tr td:nth-child(3) {
  width: 15% !important;
}

.neutral-vacation .MuiTable-root tr th:nth-child(4),
.neutral-vacation .MuiTable-root tr td:nth-child(4) {
  width: 12% !important;
}

.neutral-vacation .MuiTable-root tr th:nth-child(5),
.neutral-vacation .MuiTable-root tr td:nth-child(5) {
  width: 12% !important;
}

.neutral-vacation .MuiTable-root tr th:nth-child(6),
.neutral-vacation .MuiTable-root tr td:nth-child(6) {
  width: 30% !important;
}

.neutral-vacation .MuiTable-root tr th:nth-child(7),
.neutral-vacation .MuiTable-root tr td:nth-child(7) {
  width: 10% !important;
}

.neutral-vacation .MuiTable-root tr th:nth-child(8),
.neutral-vacation .MuiTable-root tr td:nth-child(8) {
  width: 6% !important;
}

@media (max-width: 1000px) {
  .neutral-vacation .neutral-vacation-form .left-column,
  .neutral-vacation .neutral-vacation-form .right-column {
    width: 100%;
  }

  .neutral-vacation .neutral-vacation-grid-header-wrapper {
    flex-direction: column !important;
  }

  .neutral-vacation .delete-multiple-vacation-button-wrapper,
  .neutral-vacation .page {
    width: 100% !important;
    padding-top: 10px !important;
  }

  .neutral-vacation .page {
    text-align: center;
  }

  /* grid filter */
  .neutral-vacation .neutral-vacation-grid-filter-inputs {
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
  }

  .neutral-vacation .neutral-vacation-grid-filter-notes,
  .neutral-vacation .neutral-vacation-grid-filter-select,
  .neutral-vacation #neutralVacationGridNotesFilter,
  .neutral-vacation #neutralVacationGridStatusFilter {
    width: 100% !important;
  }
}
