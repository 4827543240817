.neutral-vacation-model .modal-content {
  border-radius: 10px !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  padding: 5px !important;
}

.neutral-vacation-model .modal-content .modal-title {
  text-align: left;
  font: normal normal normal 32px / 37px "Helvetica Neue", sans-serif !important;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.neutral-vacation-model .modal-content .modal-body {
  text-align: center;
  font: normal normal normal 22px/26px "Helvetica Neue", sans-serif !important;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin: unset;
}

.neutral-vacation-model .modal-content .modal-header,
.neutral-vacation-model .modal-content .modal-footer {
  border: 0px !important;
}

.neutral-vacation-model .btn {
  width: 100px !important;
  font-family: "Helvetica Neue", sans-serif !important;
}

.neutral-vacation-model .modal-btn {
  background: #1468b1 0% 0% no-repeat padding-box !important;
  border-radius: 6px;
  color: white !important;
  opacity: 1;
  text-transform: none !important;
  margin-left: 5px;
  box-shadow: none;
  border: 2px solid #1468b1 !important;
  font-weight: bold;
}

.neutral-vacation-model .modal-btn:hover {
  background: #135187 0% 0% no-repeat padding-box !important;
  border: 2px solid #135187 !important;
}

.neutral-vacation-model .page-btn {
  color: #1468b1 !important;
  font-weight: bold;
  background-color: white !important;
  border: 2px solid #1468b1 !important;
  margin-top: 0px !important;
  text-transform: none !important;
  box-shadow: none;
}

.neutral-vacation-model .page-btn:hover {
  background-color: #eff7ff !important;
  border: 2px solid #135187 !important;
}
