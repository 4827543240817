.leftt {
  color: #8e8e8e;
  font-size: 13px;
  font-weight: 600;
  padding: 0;
  margin: 0;
}
.rightt {
  color: black;
  font-size: 13px;
  font-weight: 600;
}
span.error-message.cerror {
  position: absolute;
  bottom: -28px;
  left: 35px;
}
body .disabledBtn.additionalbtn2 {
  border: 2px solid #b5babe;
  color: #b5babe;
}
.hideerror input {
  border-color: #7c7c7c !important;
}
.hideerror .error-message {
  visibility: hidden !important;
}
#focusInput {
  width: 1px;
  height: 1px;
  opacity: 0 !important;
}
.SelectField {
  background-color: #fff;
  border-radius: 0;
  border: 1px solid lightgray;
}
.col-md-2 {
  flex: 0 0 auto;
}
.uploaded-files {
  margin-bottom: 15px !important;
}
.col-1 {
  flex: 0 0 auto;
  width: 16%;
}
.additionalbtn2 {
  padding: 0px 18px !important;
  border-radius: 4px;
  font-size: 12px !important;
  background-color: white !important;
  box-shadow: none;
  border: 2px solid #337ab7;
  color: #337ab7;
  font-weight: 700;
  height: 2rem;
  min-width: 8rem;
  margin-bottom: 0.5rem;
}
.SelectField2 {
  border-radius: 3px !important;
  border: 1px solid lightgray !important;
  width: 100% !important;
  padding: 7px !important;
}
//Disable after sending docusign
.disablediv {
  pointer-events: none !important;
  opacity: 0.4 !important;
}
.add-participants {
  margin-bottom: 0.5rem;
  input[type="text"] {
    margin-top: 0.5rem;
  }
}
.drop-title {
  cursor: pointer;
}
.dropSubTitle {
  display: block;
  margin-left: 0.5rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: none !important;
  border: 1px solid #b5b5b5;
  border-radius: 4px;
  font: normal normal 1rem "Helvetica Neue", sans-serif;
  font-size: 12px !important;
  padding: 0.21rem 2.25rem;
}
.casestructure .col-2 {
  width: 10.666667% !important;
}

#decisionTemplate * {
  font-family: Calibri, sans-serif;
  font-size: 25px !important;
}
.decmodal-content .modal-content {
  // width: 300% !important;
  // margin-left: -520px !important;
  width: 70rem !important;
  margin-left: -20rem !important;
}
.uploadFiles .file-name {
  width: 15rem !important;
}
.no-submission {
  border: 2px solid;
  border-color: #ccc;
  margin-bottom: 0.75rem;
  span {
    text-align: center;
  }
}
.response-submission {
  // border: 2px solid;
  // border-color: #ccc;
  // margin-bottom: 0.75rem;
  .action {
    margin: 0 0.5rem;
    cursor: pointer;
    float: left;
  }
  .file-name {
    margin: 0;
    white-space: normal;
    word-wrap: break-word;
    width: calc(100% - 120px);
    text-align: left;
    float: left;
  }
}

.MuiDialog-root {
  z-index: 10000 !important;
}
.decision-timesheet {
  width: 100%;
  margin: 0 0 0.5rem;
  th {
    font-weight: bold;
    padding: 3px;
  }
  td {
    padding: 3px;
  }
}

.uploadFiles {
  margin-top: 10px;
  display: block;
  input.file-action-radio {
    box-sizing: border-box;
    height: 13px;
    width: 13px;
  }
  div.file-action {
    text-align: left;
  }

  .action {
    margin: 0;
    cursor: pointer;
    float: left;
  }
  .file-name {
    margin: 0;
    white-space: normal;
    word-wrap: break-word;
    width: 21rem;
    float: left;
    text-align: left;
  }
}

.uploadbtn {
  width: auto !important;
  margin: 10px 0px 10px 0px !important;
  background-color: #818b93 !important;
  border: none !important;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
  box-shadow: none !important;
  border-radius: 5px 5px 0px 0px !important;
  color: white !important;
}
.historybtn {
  width: auto !important;
  margin: 10px 10px 10px 5px !important;
  background-color: #818b93 !important;
  border: none !important;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
  border-radius: 5px 5px 0px 0px !important;
  box-shadow: none !important;
  color: white !important;
}
.historybtn2 {
  width: auto;
  margin: 10px 10px 10px 5px;
  background-color: white;
  border: 1px solid #b4b4b4;
  margin-bottom: 0;
  padding-bottom: 0;
  border-radius: 5px 5px 0px 0px;
  box-shadow: none;
  color: #818b93;
}
.file-action label {
  margin-right: 10px;
}
.fi-file-uploader-wrapper ul {
  margin: 0;
  padding-left: 0;
  font-size: 13px;
}
.disableditem span.drop-title {
  opacity: 0.6;
}
.hideerror .pmasec textarea {
  border-color: #c7c7c7 !important;
}
.hide--required .custom--required {
  visibility: hidden;
}

// @media only screen and (max-width: 1700px) {
//   .decmodal-content .modal-content{
//     width: 300% !important;
//     margin-left: calc(25%-10px) !important;
//   }
// }
body .dollerDiv {
  display: inline-block;
  width: 115px;
  position: relative;
}
body .dollerDiv input[type="text"] {
  width: 80%;
}

@media only screen and (max-width: 600px) {
  .uploadFiles div.file-action {
    width: 190px;
  }
  .decision-timesheet {
    width: 100%;
  }
  .add-participants {
    input[type="text"] {
      width: calc(100% - 67%);
    }
    input[type="text"]:nth-child(3) {
      width: 100% !important;
    }
  }
  body .dollerDiv {
    width: 24%;
  }
  .dollerDiv input[type="text"] {
    width: calc(100% - 19%) !important;
  }
  .table1 {
    border: 0;
  }

  .table1 caption {
    font-size: 1.3em;
  }

  .table1 thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .table1 tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }

  .table1 td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: left;
  }

  .table1 td::before {
    /*
  * aria-label has no advantage, it won't be read inside a table
  content: attr(aria-label);
  */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
    width: 60%;
    margin-right: 1rem;
    line-height: 1;
  }

  .table1 td:last-child {
    border-bottom: 0;
  }
}

.decision-page {
  .action {
    cursor: pointer;
  }
}

//// grammarly-editor-plugin {
////   --grammarly-default-border-radius: 0px  !important;
//// }

._loading_overlay_wrapper._loading_overlay_wrapper--active.css-79elbk {
  height: 100vh;
  overflow-y: hidden;
}

.hide--required .disableditem span.drop-title {
  cursor: default;
}

span.custom--required {
  top: 0;
}

@media only screen and (max-width: 600px) {
  .hideerror input[type="email"] {
    width: 100% !important;
  }
}

.modal-decisionTemplateDialog .modal-decisionTemplateDialogContent {
  // width: 300% !important;
  // margin-left: -520px !important;
  width: 1200px !important;
  margin-left: -20rem !important;
}

.modal-decisionTemplateDialog .modal-decisionTemplateDialogFooter {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.modal-decisionTemplateDialog .modal-decisionTemplateDialogFooter button {
  letter-spacing: 0.5px;
  font-size: 14px;
  text-transform: initial;
  font-family: sans-serif;
}

.apply-decision-template-cases .MuiIconButton-label .MuiSvgIcon-root,
.preview-e-sign-docs-for-selected-cases .MuiIconButton-label .MuiSvgIcon-root {
  fill: #337ab7 !important;
}
