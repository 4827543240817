body {
    font-family: "Helvetica Neue", sans-serif !important;
  }
  
  .contact--lists div.MuiToolbar-root {
    padding-left: 0px;
  }
  
  body .contact--lists h6.MuiTypography-root {
    text-align: left;
    font: normal normal bold 32px/39px "Helvetica Neue", sans-serif;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    font-family: "Helvetica Neue", sans-serif !important;
  }
  a.disabledlink {
    opacity: 0.5 !important;
    cursor: default !important;
  }
  
  .contact--lists a.audit-trial {
    text-decoration: none !important;
    font: normal normal bold 16px/19px "Helvetica Neue", sans-serif !important;
    color: #1468b1 !important;
    font-family: "Helvetica Neue", sans-serif !important;
    margin: 0;
    padding: 0;
  }
  .contact--lists a.audit-trial:hover {
    text-decoration: underline;
  }
  .contact--lists a.audit-trial-readonly {
    text-decoration: none !important;
    font: normal normal bold 16px/19px "Helvetica Neue", sans-serif !important;
    color: #777777 !important;
    font-family: "Helvetica Neue", sans-serif !important;
    margin: 0;
    padding: 0;
    cursor: not-allowed;
  }
  
  .contact--lists .Mui-selected {
    color: #1468b1 !important;
  }
  
  .contact--lists .create-new-contact-btn:disabled {
    color: #919191 !important;
  }
  
  .contact--lists .create-new-contact-btn:hover {
    color: #1468b1 !important;
  }
  
  .contact--lists .subTabs .Mui-selected {
    background-color: #000000;
    color: #ffffff !important;
    font-size: 22px !important;
  }
  
  .contact--lists th.MuiTableCell-root {
    text-align: left;
    font: normal normal medium 16px/19px "Helvetica", sans-serif;
    color: black;
    letter-spacing: 0;
    opacity: 1;
  }
  
  .contact--lists tr.MuiTableRow-root {
    text-align: left;
    font: normal normal medium 17px/21px "Helvetica", sans-serif;
    color: #777777;
    opacity: 1;
  }
  
  .contact--lists td.MuiTableCell-root {
    letter-spacing: 1.36px;
    border-color: #000000;
  }
  
  .contact--lists td.MuiTableCell-footer {
    border: none;
  }
  
  .contact--lists .MuiTabs-flexContainer {
    overflow-x: auto;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }
  
  .contact--lists .MuiTabs-flexContainer::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  
  .contact--lists .MuiTableHead-root {
    margin-top: 40px !important;
  }
  
  .contact--lists .MuiTablePagination-displayedRows {
    margin-bottom: 0px;
  }
  
  .contact--lists .MuiTablePagination-selectLabel {
    margin-bottom: 0px;
  }
  
  .contact--lists .main-div {
    margin-top: 0px !important;
    padding-bottom: 0px !important;
  }
  
  .contact--lists .contact-header-container {
    margin-top: -6px;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    padding-left: 2%;
    padding-right: 2%;
    position: relative;
  }
  
  .contact--lists .contact-header-child1 {
    padding-right: 30px;
  }
  
  .contact--lists .contact-header-child2 {
    flex: 7;
    padding-right: 10%;
    min-height: 110px;
  }
  .contact--lists .main-title {
    font-size: 2rem;
    font-weight: bolder;
    line-height: normal;
    font-family: "Helvetica Neue", sans-serif !important;
  }
  .contact--lists .flex-inline-block {
    display: flex;
    padding: 0 10px;
    align-items: center;
    vertical-align: middle;
  
    justify-content: space-around;
  }
  .contact--lists .main-tab-btn {
    font-size: 22px;
    text-transform: none;
    color: black;
    padding: 12px 16px;
  }
  .contact--lists .contact-header-childs #globalsearch {
    height: 1.4375em;
    padding: 8.5px 14px;
  }
  .contact--lists .sub-tab-btn {
    font-size: 20px;
    text-transform: none;
    color: black;
    padding: 12px 16px;
  }
  .contact--lists .custom-search .MuiFormControl-root .MuiInputBase-root,
  .custom-search .flex-inline-block .MuiInputBase-root {
    height: auto;
    font-size: 1rem;
  }
  .contact--lists .custom-search .custom-text-box {
    min-width: calc(100% - 180px);
    margin-left: 8px;
    margin-right: 5px;
  }
  .contact--lists .custom-search .black-btn:hover {
    background: #135187;
  }
  .contact--lists .custom-search .wdth160 {
    min-width: 165px;
  }
  .updateSpecility button,
  .updateSpecility button:hover {
    background: #2d7a5d;
    min-height: 56px;
    position: relative;
    margin-left: -6px;
    color: #fff;
    border-radius: 0 0 0 0;
  }
  .categories-list-item li:hover span.cat--edit {
    display: inline;
  }
  .categories-list-item li span.cat--edit {
    background: #f7f7f7;
    display: none;
  }
  
  .categories-list-item li span.cat--edit svg {
    color: #4e4b4b;
  }
  
  .updateSpecility button svg {
    width: 43px;
    height: 43px;
  }
  .contact--lists .custom-search .wdth120 {
    min-width: 120px;
  }
  .categories--list .sub--title {
    font-size: 2rem;
    font-weight: bolder;
    padding-left: 60px;
    line-height: normal;
    font-family: "Helvetica Neue", sans-serif !important;
  }
  .categories--list .categories-sublist {
    display: flex;
    flex-wrap: wrap;
    margin-left: 40px !important;
  }
  .categories--list .select-all {
    text-align: right;
    margin-right: 25%;
    margin-bottom: 10px;
  }
  .categories--list .button-group button {
    min-width: 110px;
    margin-left: 69px;
  }
  .categories--list .handleSaveCategory {
    margin-top: 20px;
  }
  .categories--list .handleSaveCategory .custom-btn {
    margin-top: 7px;
  }
  .contact--lists
    .meta-grid-contact-item
    .MuiInputBase-root
    .MuiInputAdornment-root
    button {
    background-color: transparent !important;
  }
  .handleSaveCategory .catename {
    margin-right: 10px;
  }
  .categories-sublist li {
    flex: 0 0 50%;
  }
  .categories-list-item li.selectedItem {
    background: #d1d1d1;
  }
  .categories-list-item li span {
    cursor: pointer;
  }
  .categories-list-item li {
    border-top: 2px solid #000;
    text-align: center;
    justify-content: center;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .categories--list .custom-btn {
    color: #1468b1;
    font-weight: bold;
    background-color: white;
    border: 2px solid #1468b1;
    border-radius: 6px;
    text-transform: capitalize;
    padding: 6px 8px;
    margin-right: 5px;
  }
  
  .categories--list .custom-btn:hover {
    color: #135187;
    border: 2px solid #135187;
  }
  
  .contact--lists .custom-search .black-btn {
    background: #1468b1;
    font-weight: bold;
    color: white !important;
    border: 2px solid;
    border-radius: 6px;
    text-transform: capitalize;
    padding: 6px 8px;
  }
  .contact--lists .audit-btn {
    text-align: left;
  }
  .contact--lists
    .contact-header-container
    .contact-header-child3
    .MuiInputBase-root {
    font-size: 1rem;
    height: auto;
  }
  .contact--lists .contact-header-child3 {
    flex: 3;
    text-align: center;
    margin-top: 10px;
  }
  
  .contact--lists .contact-header-child4 {
    text-align: center;
    position: absolute;
    right: 0;
    top: 85px;
    margin-right: 4%;
  }
  .contact--lists .custom-search {
    margin-top: 15px;
  }
  .contact--lists .custom-search label.MuiFormLabel-root {
    text-align: left;
  }
  .contact--lists .custom-search .MuiInputBase-input::placeholder,
  .contact--lists .custom-search .MuiInputBase-input::-moz-placeholder,
  .contact--lists .custom-search .MuiInputBase-input::-webkit-input-placeholder {
    font-weight: normal !important;
  }
  
  .contact--lists .meta-grid-contact-item .main-div > div > .MuiToolbar-gutters {
    display: block;
    padding: 0px;
  }
  .handleSaveCategory .catename.b-error {
    border: 1px solid #f00 !important;
    border-radius: 6px;
  }
  .contact--lists
    .meta-grid-contact-item
    .MuiToolbar-root
    .MuiFormControl-root
    > div::before {
    border: 0px !important;
  }
  .contact--lists
    .meta-grid-contact-item
    .MuiToolbar-root
    .MuiFormControl-root
    .material-icons {
    font-size: 1.25rem;
  }
  .meta-grid-contact-item .MuiToolbar-root .MuiFormControl-root {
    border: 1px solid rgba(0, 0, 0, 0.42);
    padding: 0px;
    width: 100%;
    margin-top: 25px;
  }
  .contact--lists .disabledbtn,
  .categories--list .disabledbtn {
    opacity: 0.6;
  }
  .contact--lists .mb-btm20,
  .categories--list .mb-btm20 {
    margin-bottom: 20px;
  }
  .search--icon {
    cursor: pointer;
  }
  .custom-text-box-mul .MuiInputBase-root {
    padding: 1px !important;
  }
  @media (min-width: 600px) {
    .meta-grid-contact-item .main-div .MuiToolbar-regular {
      min-height: 64px !important;
    }
    .meta-grid-contact-item.contact--list--table
      .main-div
      > div
      > .MuiToolbar-regular {
      display: none !important;
    }
    .meta-grid-contact-item table tfoot tr td.MuiTableCell-root {
      height: auto;
      max-width: 100%;
    }
    .meta-grid-contact-item table tr td.MuiTableCell-root {
      height: 58px;
      word-wrap: break-word;
      max-width: 300px;
  
      padding-left: 4px;
      padding-right: 4px;
    }
  }
  
  @media screen and (max-width: 1030px) {
    .contact--lists .contact-header-child4 {
      margin-right: 1rem;
    }
  }
  
  @media (max-width: 1000px) {
    .contact--lists .contact-header-container {
      justify-content: center;
      flex-direction: column;
    }
  
    .contact--lists .contact-header-childs {
      margin-top: 5px;
      text-align: center;
    }
  
    .contact--lists .contact-header-child1 {
      padding-right: 0px;
    }
  
    .contact--lists .contact-header-child2 {
      padding-right: 0%;
      width: 100%;
      order: 4;
    }
  
    .contact--lists .contact-header-child3 {
      width: 100%;
      order: 3;
    }
  
    .contact--lists .contact-header-child4 {
      position: absolute;
      margin-right: 0;
      top: 2rem;
      right: 0.7rem;
    }
  }
  @media (max-width: 767px) {
    .contact--lists .mb-mobile,
    .categories--list .mb-mobile {
      margin-bottom: 13px !important;
    }
    .contact--lists .subFiltersLabel {
      min-width: 100px;
      text-align: left;
    }
    .contact--lists .contact-header-child3 {
      text-align: center !important;
      margin: 10px 0;
      display: block;
    }
    .contact--lists .audit-btn {
      text-align: center;
    }
  }
  
  .tabHeaderTitle .MuiButtonBase-root,
  .contact-header-childs.contact-header-child4 Button {
    font-family: "Helvetica Neue", sans-serif !important;
  }
  
  body .contact-header-childs.contact-header-child4 Button {
    min-width: 210px;
    font-size: 18px;
  }
  .contact-header-childs .srchBox {
    width: 218px;
  }
  .contact--lists .water-mark-search {
    display: flex;
    align-items: center;
    justify-content: right;
  }
  .back--btn {
    font-family: "HelveticaNeueCondensedBold", -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
    font-size: 18px !important;
  }
  .contact--lists .searchIconDiv {
    position: relative;
  }
  .contact--lists .searchIconDiv .MuiFormControl-root:nth-child(2) {
    width: calc(100% - 70px) !important;
    min-width: calc(100% - 100px) !important;
    margin-right: 40px !important;
  }
  .contact--lists .searchIconDiv .iconCircle {
    position: absolute;
    top: 3.5rem;
    right: 2rem;
  }
  
  .contact--lists .contact-header-child2 .MuiBox-root {
    width: 570px;
  }
  
  .tbleDiv table.MuiTable-root td.MuiTableCell-root {
    border: 0;
    color: black !important;
  }
  .contact--lists
    .tbleDiv
    table.MuiTable-root
    thead.MuiTableHead-root
    th.MuiTableCell-root {
    background: #ecf3f8;
    font-size: 16px;
    font-weight: bold;
    padding: 0.75rem 8px;
    color: black;
  }
  
  .tbleDiv table.MuiTable-root tbody tr:nth-child(odd) {
    background: #f8f8f8;
  }
  
  .linkStyle {
    color: #1468b1;
    text-decoration: none;
  }
  
  .linkStyle:hover {
    text-decoration: underline;
  }
  .contactHeaderFixed {
    position: fixed !important;
    top: 70px;
    background: #fff;
    padding: 20px 30px 30px;
    z-index: 11;
    width: 100%;
  }
  @media screen and (max-width: 1920px) {
    .searchIconDiv {
      margin-top: calc(155px + (1920px - 100vw) / 10);
    }
  }
  @media screen and (max-width: 1000px) {
    .searchIconDiv {
      margin-top: 300px;
    }
  }
  @media screen and (max-width: 765px) {
    .searchIconDiv {
      margin-top: 380px;
    }
  }
  
  @media screen and (max-width: 600px) {
    .MTableToolbar-root-5 {
      display: none !important;
    }
  }
  .MuiPopper-root.MuiTooltip-popper {
    font-size: 12px !important;
  }
  
  .base-Popper-root.MuiTooltip-popper .MuiTooltip-tooltip {
    font-size: 12px !important;
  }
  
  .MuiTooltip-popper[data-popper-placement*="bottom"] {
    font-size: 12px !important;
  }
  
  @media screen and (max-width: 1030px) {
    .contact-header-childs .srchBox {
      width: 180px;
    }
  }
  
  @media screen and (max-width: 765px) {
    .contact--lists .contact-header-child4 {
      position: relative;
      top: 0.5rem;
      width: 230px;
      margin: 0 auto;
      right: 0;
    }
    .contact--lists .water-mark-search {
      margin: 1rem 0 0;
    }
    .contact-header-childs .srchBox {
      width: calc(100% - 40px);
    }
    .contact--lists .contact-header-child2 .MuiBox-root {
      width: 100%;
      overflow: auto;
    }
    .subTabs .MuiTabs-indicator {
      background: transparent !important;
    }
  }
  
  .contact--lists .searchIconDiv .custom-search {
    margin: 185px 0 0;
  }
  .contact--lists .table--pagination {
    margin: 0 25px;
  }
  
  .transparent--loader {
    position: fixed;
    z-index: 9999999;
    left: 0;
    top: 0px;
    right: 0px;
    bottom: 0px;
    background: rgba(0, 0, 0, 0.4);
  }
  
  .loading {
    width: 100px;
    height: 100px;
  
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  
    margin: auto;
  }